<template>
<div>
  <div id="main" class="site-main">

  <div id="main-content" class="main-content">

<mainslider/>
  <div id="page-title" class="page-title-block page-title-style-1 has-background-image" style="background-image: url(/assets/backgrounds/title/06.jpg);"><div class="container"><div class="page-title-icon"><div class="sc-icon sc-icon-size-medium angle-90deg sc-icon-shape-circle sc-simple-icon" style=""><span class="sc-icon-half-1" style="color: #ffffff;"><span class="back-angle">&#xe781;</span></span><span class="sc-icon-half-2" style="color: #ffffff;"><span class="back-angle">&#xe781;</span></span></div></div><div class="page-title-title"><h1 style="">  О нас</h1></div></div></div>




  <div class="block-content">
  	<div class="container">
  		<div class="panel row">

  			<div class="panel-center col-xs-12">
  				<article id="post-11679" class="post-11679 page type-page status-publish hentry">


  					<div class="entry-content post-content">
  												<div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-9"><div class="vc_column-inner "><div class="wpb_wrapper">
  	<div class="wpb_text_column wpb_content_element " >
  		<div class="wpb_wrapper">
  			<div class="title-h3">АССОЦИАЦИЯ</div>

  		</div>
  	</div>

  	<div ></div><div class="sc_accordion wpb_content_element  not-column-inherit" data-collapsible="no" data-active-tab="1">
  		<div class="sc_accordion_wrapper ui-accordion " >

  		</div>
  	</div>
  			<div class="sc_accordion_section group">
  				<div class="sc_accordion_header ui-accordion-header"><a href="#%d0%b8%d1%81%d1%82%d0%be%d1%80%d0%b8%d1%8f-%d1%81%d0%be%d0%b7%d0%b4%d0%b0%d0%bd%d0%b8%d1%8f-%d0%b0%d1%80%d1%81%d1%82">ИСТОРИЯ СОЗДАНИЯ АРСТ</a></div>
  				<div class="sc_accordion_content ui-accordion-content vc_clearfix">

  	<div class="wpb_text_column wpb_content_element " >
  		<div class="wpb_wrapper">
  			<p>В марте 2015 года в рамках круглого стола «Туризм в интересах старшего поколения» предложили инициативу по созданию Ассоциации развития по социальному туризму (АРСТ), которая была поддержана Государственной Думой и Советом Федерации.</p>
  <p>Была создана рабочая группа и проработана концепция и положение, на базе которых были проработаны необходимые технологии по разработке и внедрению социального туризма.</p>
  <p>В рамках рабочих групп начинается оценка «Социальных туристских паспортов», которые были высланы до октября 2015 г. из 26 российских регионов. На основании этих документов уже подготовлен перечень маршрутов по каждому региону по социальному туризму.</p>

  		</div>
  	</div>
  <div class="clearboth"></div><div class="sc-divider" style="margin-bottom: -33px;"></div>
  				</div>
  			</div>

  			<div class="sc_accordion_section group">
  				<div class="sc_accordion_header ui-accordion-header"><a href="#%d0%bc%d0%b8%d1%81%d1%81%d0%b8%d1%8f-%d0%b0%d1%80%d1%81%d1%82">МИССИЯ АРСТ</a></div>
  				<div class="sc_accordion_content ui-accordion-content vc_clearfix">

  	<div class="wpb_text_column wpb_content_element " >
  		<div class="wpb_wrapper">
  			<p>Создание условий для развития туризма для социально не защищенных слоев населения. Объединение существующих и планируемых концепций развития социального туризма в регионах РФ и соответствующих ведомствах.</p>
  <p>Взаимодействие с профильными ведомствами в рамках рабочих групп для формирования специальных программ по социальному туризму для социально незащищенных групп населения по категориям: медицина, религия, природа, образование, межэтнический обмен, военно-патриотическая тематика и другим.</p>
  <p>При помощи «Социального туристического паспорта» создание в регионах зон, на базе которых будут реализованы маршруты в рамках специальных проектов.</p>

  		</div>
  	</div>

  				</div>
  			</div>
  <div class="clearboth"></div><div class="sc-divider" style="margin-bottom: 9px;"></div></div></div></div><div class="wpb_column vc_column_container vc_col-sm-3"><div class="vc_column-inner "><div class="wpb_wrapper">
  	<div class="wpb_text_column wpb_content_element " >
  		<div class="wpb_wrapper">
  			<div class="title-h3">ЗАДАТЬ ВОПРОС</div>
  <div role="form" class="wpcf7" id="wpcf7-f590-p11679-o1" lang="en-US" dir="ltr">
  <div class="screen-reader-response"></div>
<aboutformavopros />
  </div>

  		</div>
  	</div>
  </div></div></div></div><div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner "><div class="wpb_wrapper">
  	<div class="wpb_text_column wpb_content_element " >
  		<div class="wpb_wrapper">

  		</div>
  	</div>
  </div></div></div></div><div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner "><div class="wpb_wrapper"><div class="clearboth"></div><div class="sc-divider" style="margin-bottom: 27px;"></div><div class="fullwidth-block clearfix" style="background-position: center top;"><div class="sc-map-with-text"><div class="sc-map-with-text-content" style="background-color: rgba(128,158,170,0.78);"><div class="container"><div class="vc_row wpb_row vc_inner vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-6"><div class="vc_column-inner "><div class="wpb_wrapper">
  	<div class="wpb_text_column wpb_content_element  resp-middle" >
  		<div class="wpb_wrapper">
  			<h3><span style="color: #ffffff;">МЫ РАБОТАЕМ</span></h3>

  		</div>
  	</div>
  <div class="sc-icon-with-text sc-icon-with-text-icon-size-big"><div class="sc-icon-with-text-icon"><div class="sc-icon sc-icon-size-big angle-90deg sc-icon-shape-square sc-simple-icon" style=""><span class="sc-icon-half-1" style="color: #ffa188;"><span class="back-angle">&#xe6ec;</span></span><span class="sc-icon-half-2" style="color: #ff7070;"><span class="back-angle">&#xe6ec;</span></span></div></div><div class="sc-icon-with-text-content"><div class="sc-icon-with-text-text">
  	<div class="wpb_text_column wpb_content_element " >
  		<div class="wpb_wrapper">
  			<p><span style="color: #ffffff;">Понедельник-Пятница: 10:00 – 20:00</span><br />
  <span style="color: #ffffff;"> Суббота-Воскресенье: выходные дни</span></p>

  		</div>
  	</div>
  <div class="clearboth"></div><div class="sc-divider" style="margin-bottom: 10px;"></div></div></div><div class="clearboth"></div></div></div></div></div><div class="wpb_column vc_column_container vc_col-sm-6"><div class="vc_column-inner "><div class="wpb_wrapper">
  	<div class="wpb_text_column wpb_content_element  resp-middle" >
  		<div class="wpb_wrapper">
  			<h3><span style="color: #ffffff;">НАШ АДРЕС</span></h3>

  		</div>
  	</div>
  <div class="sc-icon-with-text sc-icon-with-text-icon-size-big"><div class="sc-icon-with-text-icon"><div class="sc-icon sc-icon-size-big angle-90deg sc-icon-shape-square sc-simple-icon" style=""><span class="sc-icon-half-1" style="color: #fb9f87;"><span class="back-angle">&#xe6df;</span></span><span class="sc-icon-half-2" style="color: #ff7070;"><span class="back-angle">&#xe6df;</span></span></div></div><div class="sc-icon-with-text-content"><div class="sc-icon-with-text-text">
  	<div class="wpb_text_column wpb_content_element " >
  		<div class="wpb_wrapper">
  			<p><span style="color: #ffffff;">Россия, Москва, 107140, ул. Верхняя Красносельская, д. 3, стр. 1, 6 этаж</span><br />
  <span style="color: #ffffff;"> Телефон: 8 (495) 7- 801-802</span><br />
  <span style="color: #ffffff;"> Email: info@aorst.ru</span></p>

  		</div>
  	</div>
  </div></div><div class="clearboth"></div></div></div></div></div></div></div></div><div class="sc-map-with-text-map grayscale">
  <div class="wpb_gmaps_widget wpb_content_element">
  		<div class="wpb_wrapper">
  		<div class="wpb_map_wraper">
  			<iframe src="https://www.google.com/maps/d/embed?mid=zsnnissACBg4.kmqVuzHe1LKM" width="640" height="596"></iframe>		</div>
  	</div></div></div></div><div class="clearboth"></div><div class="sc-divider" style="margin-top: -140px;"></div></div></div></div></div></div></p>
  					</div><!-- .entry-content -->







  				</article><!-- #post-## -->

  			</div>


  		</div>


  	</div>
  </div>



  </div><!-- #main-content -->



</div>
</div>
</template>

<script>
import mainslider from "@/components/mainslider.vue"
import {rollup} from "@/lib/utils.js"
import aboutformavopros from "@/components/about/formavopros"
export default {
name : "About",

components : {mainslider,aboutformavopros},
mounted() {
rollup()

}

}
</script>
