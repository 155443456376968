<template>
  <form action="/?page_id=11679#wpcf7-f590-p11679-o1" method="post" class="wpcf7-form" novalidate="novalidate">
  <div style="display: none;">
  <input type="hidden" name="_wpcf7" value="590" />
  <input type="hidden" name="_wpcf7_version" value="5.0.1" />
  <input type="hidden" name="_wpcf7_locale" value="en_US" />
  <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f590-p11679-o1" />
  <input type="hidden" name="_wpcf7_container_post" value="11679" />
  </div>
  <div class="standart-mc-form">
  <p>Ваше имя<br />
      <span class="wpcf7-form-control-wrap your-name"><input type="text" name="your-name" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" /></span> </p>
  <p>Ваш Email<br />
      <span class="wpcf7-form-control-wrap your-email"><input type="email" name="your-email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false" /></span> </p>
  <p>Тема сообщения<br />
       <span class="wpcf7-form-control-wrap your-subject"><input type="text" name="your-subject" value="" size="40" class="wpcf7-form-control wpcf7-text" aria-invalid="false" /></span></p>
  <p>Ваше сообщение<br />
      <span class="wpcf7-form-control-wrap your-message"><textarea name="your-message" cols="40" rows="10" class="wpcf7-form-control wpcf7-textarea" aria-invalid="false"></textarea></span> </p>
  <p><input type="submit" value="Отправить сообщение" class="wpcf7-form-control wpcf7-submit" /></p>
  </div>
  <div class="wpcf7-response-output wpcf7-display-none"></div></form>
</template>

<script>
export default {
name : 'aboutformavopros'


}
</script>
